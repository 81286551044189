import {Component, OnInit} from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {Observable, Subscription} from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import {MenuItem} from '../../model/menu-item';
import {Funtions} from '../../model/security/funtionalities';
import {UserAppService} from '../../services/user-service/user-app.service';
import {Store} from '@ngrx/store';
import {RuleState, selectRuleState} from '../../store/rule/rule.state';
import {UserRuleState} from '../../store/rule/reducers/rule.reducer';
import {MemberStatus} from '../../model/organisation/Member';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit{

  protected menu: MenuItem[] = [];
  public currentUserMenu: MenuItem[];

  rule$: Observable<any>;
  ruleSubscription: Subscription;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(private breakpointObserver: BreakpointObserver,
              protected ruleStore: Store<RuleState>,
              private userService: UserAppService) {
    this.rule$ = this.ruleStore.select(selectRuleState);
  }

  ngOnInit() {
    this.initMenu();

    this.ruleSubscription = this.rule$.subscribe((state: UserRuleState) => {
      this.initMenu();
    });
  }

  protected initMenu() {
    this.menu = [
      {
        menuTitle: 'app.menu.dash',
        menuAvatarUrl: 'assets/images/dash.jpeg',
        routeData: ['/dashboard'],
        functionalities: [
          Funtions.dashboard
        ]
      },
      {
        menuTitle: 'app.menu.payment',
        menuAvatarUrl: 'assets/images/payment.jpg',
        routeData: ['/deposit/make'],
        functionalities: [
          Funtions.make_deposit
        ]
      },
      {
        menuTitle: 'app.menu.association',
        menuAvatarUrl: 'assets/images/association.png',
        functionalities: [
          Funtions.association
        ]
      },
      {
        menuTitle: 'app.menu.partner',
        menuAvatarUrl: 'assets/images/adherant.jpg',
        functionalities: [
          Funtions.member_btn_new_fct, Funtions.member_btn_list_fct
        ],
        children: [
          /*{
            menuTitle: 'app.menu.member.new',
            routeData: ['/member/edit'],
            functionalities: [
              Funtions.member_btn_new_fct
            ]
          },*/
          {
            menuTitle: 'app.menu.member.list',
            routeData: ['/member/list'],
            functionalities: [
              Funtions.member_btn_list_fct
            ]
          }
        ]
      },
      {
        menuTitle: 'app.menu.transaction',
        menuAvatarUrl: 'assets/images/transaction.jpg',
        functionalities: [
          Funtions.transaction
        ]
      },
      {
        menuTitle: 'app.menu.notification',
        menuAvatarUrl: 'assets/images/notif.jpg',
        functionalities: [
          Funtions.notification
        ]
      },
      {
        menuTitle: 'app.menu.list',
        menuAvatarUrl: 'assets/images/partner.jpg',
        functionalities: [
          Funtions.partner_btn_list_fct
        ]
      },
      {
        menuTitle: 'app.menu.status',
      },
      {
        menuTitle: 'app.menu.document',
        menuAvatarUrl: 'assets/images/partner.jpg',
        functionalities: [
          Funtions.document_btn_list_fct
        ],
        children: [
          {
            menuTitle: 'app.menu.document.asbl',
            routeData: ['/documents/list'],
            functionalities: []
          },
          {
            menuTitle: 'app.menu.document.personal',
            routeData: ['/record-doc/list'],
            functionalities: []
          }
        ]
      },
      {
        menuTitle: 'app.menu.family.list',
        menuAvatarUrl: 'assets/images/partner.jpg',
        functionalities: [],
        children: [
          {
            menuTitle: 'family-member-home.member.title',
            routeData: ['/family/list'],
            functionalities: []
          },
          {
            menuTitle: 'family-member-home.member.active.title',
            routeData: ['/family/list', {type: MemberStatus[MemberStatus.ACTIVE]}],
            functionalities: []
          },
          {
            menuTitle: 'family-member-home.member.inactive.title',
            routeData: ['/family/list', {type: MemberStatus[MemberStatus.INACTIVE]}],
            functionalities: []
          },
          {
            menuTitle: 'family-member-home.member.on.probation.title',
            routeData: ['/family/list', {type: MemberStatus[MemberStatus.ONPROBATION]}],
            functionalities: []
          },
          {
            menuTitle: 'family-member-home.member.die.title',
            routeData: ['/family/list', {type: MemberStatus[MemberStatus.DIE]}],
            functionalities: []
          }
        ]
      },
      {
        menuTitle: 'app.menu.user.profile.label',
        menuAvatarUrl: 'assets/images/user-male.png',
        routeData: ['/manage/account'],
        functionalities: [],
      }
    ];

    this.currentUserMenu = [this.menu[10], this.menu[1], this.menu[8], this.menu[9], this.menu[3]];
    this.menu = this.menu.filter(i => {
      return this.allowAccess(i) !== null;
    });

    this.currentUserMenu = this.currentUserMenu.concat(this.menu);
  }

  private allowAccess(menuItem: MenuItem) {
    if (!menuItem.functionalities) { return null; }
    const functionalities = menuItem.functionalities.filter(f => {
      return this.userService.hasAccess(f);
    });
    if (functionalities.length === 0) { return null; }
    if (menuItem.children) {
      menuItem.children = menuItem.children.filter(i => {
        return this.allowAccess(i) !== null;
      });
    }
    return menuItem;
  }
}
