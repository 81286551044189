import { Injectable, EventEmitter } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Observable } from 'rxjs';
import {UserAppService} from '../user-service/user-app.service';

@Injectable({
  providedIn: 'root'
})
export class RouteGuardGuard implements CanActivate {

  public routeChanged: EventEmitter<string> = new EventEmitter();
  protected previousUrl: string;
  public protectedUrl: string[] = [
    'documents/list',
    'deposit/make',
    'family/list',
    'family/edit',
    'family/home',
    'family/show',
    'record-doc/list',
    'record-doc/edit',
    'record-doc/show',
  ];

  constructor(private router: Router, private userService: UserAppService){
    // retrieve previous url
    router.events.subscribe( (event) => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = event.url;
      }
    });
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const currentUrl: string = state.url !== null ? state.url : route.url != null && route.url.length > 0 ? route.url[0].path : '';

    // When user tries to access the login page but already logs in.
    if ((currentUrl.includes('login')  || currentUrl.includes('register')) && this.userService.isUserLoggedIn() === true){
      this.router.navigate(['documents/list']);
      return false;
    }

    // when user tries to access to dashboard but don't logs in.
    this.protectedUrl.forEach(el => {
      if (currentUrl.includes(el)  && (this.userService.isUserLoggedIn() === undefined || this.userService.isUserLoggedIn() === false)) {
        this.router.navigate(['login']);
        return false;
      }
    });

    // return true if a route as not define a privilleges
    if (route.data && route.data.roles && route.data.roles.length === 0) {
      return true;
    }

    /* Evalute grant access. */
    let haveAccess = false;
    const userPrivileges = this.userService.getUserRules();

    // Return false if user don't have rule
    if (!userPrivileges) {
      return haveAccess;
    }

    userPrivileges.forEach(r => {
      if (route.data && route.data.roles && route.data.roles.indexOf(r) !== -1) {
        haveAccess = true;
        this.routeChanged.emit(this.previousUrl);
      }
    });
    return haveAccess;
  }
}
