import {Component, ChangeDetectorRef, OnInit} from '@angular/core';
import { ListAbstractComponent } from '../../base/abstract/list-abstract-component';
import { Member } from 'app/model/organisation/Member';
import { ActivatedRoute, Router } from '@angular/router';
import { UserAppService } from 'app/services/user-service/user-app.service';
import { HttpService } from 'app/services/http-service/http.service';
import { NotificationService, NotificationType } from 'app/services/notification-service/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { FormAction } from '../../base/buttons-bar/buttons-bar.component';
import {ConfigService} from '@noble/services/config.service';
import {MemberShip} from '../../../model/register/MemberShip';

@Component({
  selector: 'app-member-list',
  templateUrl: './member-list.component.html',
  styleUrls: ['./member-list.component.scss']
})
export class MemberListComponent extends ListAbstractComponent<MemberShip> implements OnInit{

  constructor(
    protected ref: ChangeDetectorRef,
    protected router: Router, protected route: ActivatedRoute,
    protected httpService: HttpService<MemberShip>,
    protected userService: UserAppService,
    protected msgService: NotificationService, protected translateService: TranslateService,
    private _configService: ConfigService
  ) {
    super(ref, httpService, router, route, userService, msgService, translateService);
    this.searchBaseUrl = 'organisation/member-ship';

    this._configService.config = {
      layout: {
        style   : 'app-navbar',
      }
    };

    this.buttons = [
      /*{id: 'add', value: FormAction.CREATE, text: 'btn.create.label', cssClass: 'btn-create' , disabled: false,
        icon: {type : 'add_circle_outline', color: '#0099cc'}},
      {id: 'edit', value: FormAction.UPDATE, text: 'btn.update.label', cssClass: 'btn-update' , disabled: false,
        icon: {type : 'edit', color: '#ff9933'}},*/
      {id: 'delete', value: FormAction.DELETE, text: 'btn.delete.label', cssClass: 'btn-delete' , disabled: false,
        icon: {type : 'delete_forever', color: '#ff9933'}},
    ];
  }

  ngOnInit() {
    super.ngOnInit();
    this.searchClicked({});
  }

  public viewElement(element) {
    this.router.navigate(['member/show', {id: element.id}]);
  }

  btnActionClick(event) {
    if (event && event.id === this.buttons[0].id ){
      this.router.navigate(['member/show']);
    } else if (event && event.id === this.buttons[1].id){
      if ( !this.selection.selected || this.selection.selected.length !== 1) {
        this.msgService.log('member.list.select.to.modify.error', NotificationType.ERROR);
      }else{
        this.router.navigate(['member/show', {number: this.selection.selected[0].id}]);
      }
    }
    else if (event && event.id === this.buttons[2].id){
      if ( !this.selection.selected || this.selection.selected.length < 1){
        this.msgService.log('member.list.select.to.delete.error', NotificationType.ERROR);
      }else{
        this.deleteSelected();
      }
    }
  }

  deleteSelected(){
    this.msgService.confirm(this.translate.transform('member.select.to.delete.message')).subscribe( (result) => {
      if (result === true){
          const memberSelected = this.selection.selected[0];
          this.httpService.deleteById(memberSelected.id, 'organisation/member-ship').subscribe( (val) => {
            this.msgService.log('member.delete.succeed', NotificationType.SUCCESS);

            this.searchClicked({});
          }, (error) => {
            this.msgService.log('member.delete.fail', NotificationType.ERROR);
          });
        }
      });
  }

}
