import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from './components/login/login.component';
import { RegisterComponent } from './components/register/register-edit/register.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { MakeDepositComponent } from './components/deposit/make-deposit/make-deposit.component';
import { ConfirmDepositComponent } from './components/deposit/confirm-deposit/confirm-deposit.component';
import { MemberListComponent } from './components/member/member-list/member-list.component';
import { MemberShowComponent } from './components/member/member-show/member-show.component';
import { MemberEditComponent } from './components/member/member-edit/member-edit.component';
import { HomeComponent } from './components/home/home.component';
import { OurProjectComponent } from './components/home/our-project/our-project.component';
import { MembershipComponent } from './components/home/membership/membership.component';
import { ContactUsComponent } from './components/home/contact-us/contact-us.component';
import { AboutUsComponent } from './components/home/about-us/about-us.component';
import {ConfirmAccountComponent} from './components/account/confirm-account/confirm-account.component';
import {RouteGuardGuard} from './services/guard/route-guard.guard';
import {MailConfirmComponent} from './components/account/mail-confirm/mail-confirm.component';
import {FileListComponent} from './components/file-manager/file-list/file-list.component';
import {ForgotPasswordComponent} from './components/login/forgot-password/forgot-password.component';
import {ResetPasswordComponent} from './components/login/reset-password/reset-password.component';
import {ProfileComponent} from './components/profile/profile.component';
import {ProfilePasswordComponent} from './components/profile/tabs/profile-password/profile-password.component';
import {ProfileAboutComponent} from './components/profile/tabs/profile-about/profile-about.component';
import {ProfileBeneficiaryComponent} from './components/profile/tabs/profile-beneficiary/profile-beneficiary.component';
import {Role} from './model/security/rule';
import {DashboardService} from './services/dashbord/dashboard.service';
import {FamilyMemberListComponent} from './components/family-member/family-member-list/family-member-list.component';
import {FamilyMemberEditComponent} from './components/family-member/family-member-edit/family-member-edit.component';
import {FamilyMemberShowComponent} from './components/family-member/family-member-show/family-member-show.component';
import {FamilyMemberHomeComponent} from './components/family-member/family-member-home/family-member-home.component';
import {RecordShowComponent} from './components/document/record/record-show/record-show.component';
import {RecordListComponent} from './components/document/record/record-list/record-list.component';
import {RecordEditComponent} from './components/document/record/record-edit/record-edit.component';

/*
* Routing for the items feature are stored in the items module file
*/

const routes: Routes = [
  { path: '',  redirectTo: '/home', pathMatch: 'full', canActivate: [RouteGuardGuard], data: {roles: []}}, // catch all route
  // { path: 'dashboard', component: DashboardComponent , canActivate: [AuthGuard] },
  { path: 'home',
    children: [
      {path: '', component: HomeComponent },
      {path: 'our-project', component: OurProjectComponent},
      {path: 'membership', component: MembershipComponent},
      // {path: 'recruitment', component: RecruitmentComponent, canActivate: [RouteGuardGuard], data: {roles: []}},
      // {path: 'make-donation', component: MakeDonationComponent, canActivate: [RouteGuardGuard], data: {roles: []}},
      // {path: 'blog', component: BlogComponent, canActivate: [RouteGuardGuard], data: {roles: []}},
      // {path: 'jobs', component: JobsComponent, canActivate: [RouteGuardGuard], data: {roles: []}},
      {path: 'contact-us', component: ContactUsComponent, canActivate: [RouteGuardGuard], data: {roles: []}},
      {path: 'about-us', component: AboutUsComponent },
      // {path: 'faq', component: FaqComponent, canActivate: [RouteGuardGuard], data: {roles: []}},
      {path: 'confirm', component: ConfirmAccountComponent, canActivate: [RouteGuardGuard], data: {roles: []}},
      {path: 'email', component: MailConfirmComponent, canActivate: [RouteGuardGuard], data: {roles: []}},
      // {path: 'news', component: NewsComponent, canActivate: [RouteGuardGuard], data: {roles: []}},
    ]
  },
  { path: 'dashboard', component: DashboardComponent, resolve: { dashboard: DashboardService},
    canActivate: [RouteGuardGuard], data: {roles: [Role.DASHBOARD]} },
  { path: 'login', component: LoginComponent, canActivate: [RouteGuardGuard], data: {roles: []} },
  { path: 'forgot-password', component: ForgotPasswordComponent, canActivate: [RouteGuardGuard], data: {roles: []} },
  { path: 'reset-password', component: ResetPasswordComponent, canActivate: [RouteGuardGuard], data: {roles: []} },
  { path: 'register', component: RegisterComponent, canActivate: [RouteGuardGuard], data: {roles: []} },
  { path: 'deposit', canActivate: [RouteGuardGuard], data: {roles: []},
    children: [
      { path: '',  redirectTo: '/deposit/make', pathMatch: 'full', canActivate: [RouteGuardGuard], data: {roles: []}},
      {path: 'make', component: MakeDepositComponent, canActivate: [RouteGuardGuard], data: {roles: []}},
      {path: 'confirm', component: ConfirmDepositComponent, canActivate: [RouteGuardGuard], data: {roles: []}},
    ]
  },
  { path: 'member', canActivate: [RouteGuardGuard], data: {roles: []},
    children: [
      {path: 'list', component: MemberListComponent, canActivate: [RouteGuardGuard], data: {roles: []}},
      {path: 'edit', component: MemberEditComponent, canActivate: [RouteGuardGuard], data: {roles: []}},
      {path: 'show', component: MemberShowComponent, canActivate: [RouteGuardGuard], data: {roles: []}},
    ]
  },

  /* { path: 'news-management', canActivate: [], data: {roles: [], canActivate: [RouteGuardGuard]},
    children: [
      {path: 'editor', component: NewsManagementEditorComponent, canActivate: [RouteGuardGuard], data: {roles: []}},
      {path: 'navigate', component: NewsManagementNavigateComponent, canActivate: [RouteGuardGuard], data: {roles: []}},
      {path: 'view', component: NewsManagementViewComponent, canActivate: [RouteGuardGuard], data: {roles: []}},
    ]
  },*/
  { path: 'documents', canActivate: [RouteGuardGuard], data: {roles: []},
    children: [
      { path: '',  redirectTo: '/documents/list', pathMatch: 'full', canActivate: [RouteGuardGuard] },
      {path: 'list', component: FileListComponent, canActivate: [RouteGuardGuard], data: {roles: []}},
    ]
  },
  { path: 'record-doc', canActivate: [RouteGuardGuard], data: {roles: []},
    children: [
      { path: '',  redirectTo: '/record-doc/list', pathMatch: 'full', canActivate: [] },
      {path: 'list', component: RecordListComponent, canActivate: [RouteGuardGuard], data: {roles: []}},
      {path: 'edit', component: RecordEditComponent, canActivate: [RouteGuardGuard], data: {roles: []}},
      {path: 'show', component: RecordShowComponent, canActivate: [RouteGuardGuard], data: {roles: []}},
    ]
  },
  { path: 'family', canActivate: [RouteGuardGuard], data: {roles: []},
    children: [
      { path: '',  redirectTo: '/family/list', pathMatch: 'full', canActivate: [RouteGuardGuard] },
      {path: 'list', component: FamilyMemberListComponent, canActivate: [RouteGuardGuard], data: {roles: []}},
      {path: 'edit', component: FamilyMemberEditComponent, canActivate: [RouteGuardGuard], data: {roles: []}},
      {path: 'show', component: FamilyMemberShowComponent, canActivate: [RouteGuardGuard], data: {roles: []}},
      {path: 'home', component: FamilyMemberHomeComponent, canActivate: [RouteGuardGuard], data: {roles: []}},
    ]
  },

  { path: 'manage', canActivate: [RouteGuardGuard], data: {roles: []},
    children: [
      { path: '',  redirectTo: '/manage/account', pathMatch: 'full', canActivate: [RouteGuardGuard] },
      {path: 'account', component: ProfileComponent, canActivate: [RouteGuardGuard], data: {roles: []}},
      {path: 'password', component: ProfilePasswordComponent, canActivate: [RouteGuardGuard], data: {roles: []}},
      {path: 'about', component: ProfileAboutComponent, canActivate: [RouteGuardGuard], data: {roles: []}},
      {path: 'beneficiary', component: ProfileBeneficiaryComponent, canActivate: [RouteGuardGuard], data: {roles: []}}
    ]
  },
];


@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    scrollPositionRestoration: 'top',
    scrollOffset: [0, 0],
    anchorScrolling: 'enabled',
  })]
})
export class AppRoutingModule { }
